/* eslint-disable node/no-callback-literal */

export const defaultRangeMax = 9999999

// == Check Logic == //
//= > 手機號碼檢查
export function checkPhone (rule, value, callback) {
  const home = /^0\d{1,9}$/
  const phone = /^09\d{8}$/
  if (value === '') return callback()

  if (rule.home && rule.phone) {
    if (!phone.test(value) && !home.test(value)) {
      console.log('here')
      return callback(new Error('請輸入正確格式'))
    } else {
      return callback()
    }
  } else if (!phone.test(value)) {
    return callback(new Error('請輸入正確格式'))
  } else {
    return callback()
  }
}

//= > 電子信箱檢查
export function checkEmail (rule, value, callback) {
  if (!rule.required && value === '') return callback()
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!reg.test(value)) {
    return callback(new Error('請輸入正確的電子信箱'))
  } else {
    return callback()
  }
}

//= > 網址檢查
export function checkURL (rule, value, callback) {
  const reg = /^https?:\/{2}[^\s]*/gm
  if (!value) return callback()
  if (!reg.test(value)) {
    return callback(new Error('請輸入正確的網址格式'))
  } else {
    return callback()
  }
}

export function checkDigitAndRange (rule, value, callback) {
  const reg = /^-?[0-9]*$/
  const min = rule.min
  const max = rule.max
  // if (!Number.isNaN(Number(value))) return callback()
  if (!reg.test(value)) {
    return callback(new Error('僅限填入數字'))
  } else {
    if (value > max || value < min) {
      return callback(new Error(`數值介於 ${min} 與 ${max} 之間`))
    } else return callback()
  }
}

//= > 檢查
export function checkDigit (rule, value, callback) {
  const reg = /^-?[0-9]*$/
  if (!Number.isNaN(Number(value))) return callback()
  if (!reg.test(value)) {
    return callback(new Error('只允許輸入數字'))
  } else {
    return callback()
  }
}

//= > 檢查
export function checkLength (rule, value, callback) {
  // TODO 排除符號
  const min = rule.min
  const max = rule.max

  if (value.length < min) {
    return callback(new Error(`長度介於 ${min} 與 ${max} 之間`))
  } else if (value.length > max) {
    return callback(new Error(`長度介於 ${min} 與 ${max} 之間`))
  } else return callback()
}

export function checkDomain (rule, value, callback) {
  const reg = /^[0-9a-z\\-]*$/
  if (!reg.test(value)) {
    return callback(new Error('錯誤格式'))
  } else {
    return callback()
  }
}

export function checkInteger (rule, value, callback) {
  if (Number.isInteger(Number(value))) {
    return callback()
  } else {
    return callback(new Error('請輸入正整數'))
  }
}

export const noSymbolCheck = (rule, value, callback) => {
  const pattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  const noPass = pattern.test(value)
  if (noPass) return callback(new Error('不可含有特殊符號'))
  return callback()
}

// TODO 數值範圍
export function checkRange (rule, value, callback) {
  const min = rule.min
  const max = rule.max

  if (!max) {
    if (value < min) return callback(new Error(`數值需大於 ${min}`))
  }

  if (value < min) return callback(new Error(`數值介於 ${min} 與 ${max} 之間`))
  else if (value > max) {
    return callback(new Error(`數值介於 ${min} 與 ${max} 之間`))
  } else return callback()
}

// == Rules Export == //

//= > No Empty
export const noEmptyRules = (msg = '請輸入資料', change = false) => {
  // if (!change) return { required: true, message: msg, trigger: ['blur'] }
  // if (change) return { required: true, message: msg, trigger: ['change'] }
  return { required: true, message: msg, trigger: ['change', 'blur'] }
}

export const promisePeriodDaysRules = (min, max) => {
  return { min: min, max: max, validator: checkDigitAndRange, trigger: ['change', 'blur'] }
}
//= > Username
export const usernameRules = (required = true, msg = '請輸入姓名') => {
  return [{ required: required, message: msg, trigger: 'blur' }]
}

//= > Phone
export const phoneRules = (required = true, phone = true, home = false) => {
  return {
    required: required,
    phone,
    home,
    validator: checkPhone,
    trigger: 'blur',
  }
}

//= > Email
export const emailRules = (required = true) => {
  return { required: required, validator: checkEmail, trigger: 'blur' }
}

export const emailRulesNoRequired = (msg) => {
  return { type: 'email', message: 'email 格式錯誤' || msg, trigger: 'blur' }
}

//= > URL
export const urlRules = () => {
  return { validator: checkURL, trigger: 'blur' }
}

//= > Digit
export const isDigitRules = (required = true) => {
  return { required: required, validator: checkDigit, trigger: 'blur' }
}

//= > Length
export const lengthRules = (min, max) => {
  return { min: min, max: max, validator: checkLength, trigger: 'blur' }
}

export const maxRules = (max, msg) => {
  return { max: max, message: msg || `長度不能超過${max}個字`, trigger: 'blur' }
}

export const noSymbolRules = () => {
  return { validator: noSymbolCheck, trigger: 'blur' }
}

export const rangeRules = (min = 0, max = defaultRangeMax) => {
  return { min: min, max: max, validator: checkRange, trigger: 'blur' }
}
export const minRules = (min = 0, msg) => {
  return { min: min, message: msg, validator: checkRange, trigger: 'blur' }
}

//= > No Empty
export const changeEventRules = (msg = '請輸入資料') => {
  return { required: true, message: msg, trigger: 'change' }
}

//= > No Empty
export const domainRules = (msg = '錯誤格式') => {
  return { validator: checkDomain, message: msg, trigger: 'blur' }
}

//= > Integer
export const integerRules = (msg = '請輸入正整數') => {
  return { validator: checkInteger, message: msg, trigger: 'blur' }
}

//= > checkTimeInterval
export const noEmptyTimeInterval = () => {
  return {
    trigger: 'change',
    validator: (rule, value, callback) => {
      if (value.length === 0) return callback('新設定時段')

      const hasEmptyTime = value.some((time) => !time.start || !time.end)
      if (hasEmptyTime) return callback('新設定時段')

      callback()
    },
  }
}
export const noEmptyDateTimeInterval = () => {
  return {
    trigger: 'change',
    validator: (rule, value, callback) => {
      const hasEmptyTime = value.some((time) => !time[0] || !time[1])
      if (hasEmptyTime) return callback('新設定時段')

      callback()
    },
  }
}

export const noEmptyDateTimeRangeInterval = () => {
  return {
    trigger: 'change',
    validator: (rule, value, callback) => {
      const hasEmptyTime = value.some((time) => !time.date || !time.timeEnd || !time.timeStart)
      if (hasEmptyTime) return callback('新設定時段')

      callback()
    },
  }
}

export const noEmptyWeekRangeInterval = () => {
  return {
    trigger: 'change',
    validator: (rule, value, callback) => {
      const hasEmptyTime = value.some((time) => time.week === null || !time.timeEnd || !time.timeStart)
      if (hasEmptyTime) return callback('新設定時段')

      callback()
    },
  }
}

export const noPastDate = () => {
  return {
    trigger: ['change', 'blur'],
    validator: (rule, value, callback) => {
      console.log(value)
      const hasPastDate = new Date(value) < new Date()
      if (hasPastDate) return callback('不可選擇過去時間')

      callback()
    },
  }
}

export const CVSSenderRules = () => {
  return {
    validator: checkCVSSenderName,
    trigger: 'blur',
  }
}

// -- Feature validator
export const featureValidator = (featureList, feature) => {
  let use = false
  for (const item of Object.keys(featureList)) {
    if (feature === item) {
      use = featureList[item]
    }
    const row = feature.split('.')
    while (row.length > 0) {
      row.pop()
      if ([...row, '*'].join('.') === item) use = featureList[item]
    }
  }

  return use
}

// 物流收件人名稱長度檢查
export const checkCVSSenderName = (rule, value, callback) => {
  if (/^[a-zA-Z]+$/.test(value)) {
    if (value.length < 4) {
      return callback(new Error('英文至少 4 字'))
    }
    if (value.length > 10) {
      return callback(new Error('英文最多 10 字'))
    }
    return callback()
  }
  if (/^[\u4e00-\u9fa5]+$/.test(value)) {
    if (value.length < 2) {
      return callback(new Error('中文至少 2 字'))
    }
    if (value.length > 5) {
      return callback(new Error('中文最多 5 字'))
    }
    return callback()
  }
  return callback(new Error('請輸入中文或英文'))
}
