<template>
  <el-dialog
    title="選擇標籤"
    :visible.sync="observeVisible"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
    @closed="onClosed"
    @open="onOpen"
  >
    <div v-loading="isLoading">
      <!-- ANCHOR 搜尋標籤 -->
      <div class="mb-[12px]">
        <el-input
          v-model="nameSearch"
          suffix-icon="el-icon-search"
          placeholder="請輸入標籤名稱搜尋"
          clearable
        />
      </div>
      <!-- ANCHOR 新增標籤 -->
      <div v-if="enableCreate">
        <div v-if="!toggleCreate" class="create-tag-toggle">
          <el-button class="btn-toggle-create" @click="toggleCreate = true">
            建立新標籤
            <i class="el-icon-plus" />
          </el-button>
        </div>
        <div v-if="toggleCreate" class="flex">
          <el-form
            ref="form"
            class="w-full"
            :model="formData"
            :rules="formRules"
            @submit.native.prevent
          >
            <el-form-item prop="name">
              <div class="flex items-center justify-between" style="gap: 12px">
                <el-input v-model.trim="formData.name" block class="create-tag" placeholder="請輸入標籤名稱" />
                <p class="btn confirm" @click="onCreateTag">確認</p>
                <p class="btn cancel" @click="toggleCreate = false">取消</p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- ANCHOR 選擇標籤 -->
      <div class="wrapper">
        <div class="selected-wrapper">
          <Tag
            v-for="tag in selected"
            :key="tag"
            type="action"
            closable
            @close="removeSelect(tag)"
          >
            <span class="selected-tag">
              {{ findSelectTag(tag) }}
            </span>
          </Tag>
        </div>
        <div class="list-wrapper">
          <el-checkbox-group v-model="selected" class="item-group">
            <template v-for="(item, index) in showTagList[tableOptions.page - 1]">
              <el-checkbox
                v-if="item.type === 'option'"
                :key="index"
                :label="item.option.id"
                :disabled="disabledTag(item.option)"
              >
                <p class="truncate" style="width: 220px;">{{ item.option.name }}</p>
              </el-checkbox>
              <div v-else :key="'label' + index" class="border-b border-primary-100 mr-[30px]">
                <span class="inline text-[14px] text-primary-100 font-medium">
                  {{ item.label }}
                </span>
              </div>
            </template>
          </el-checkbox-group>
          <Pagination
            class="pagination"
            :curPage.sync="tableOptions.page"
            :pageLimit="tableOptions.pageLimit"
            :total="mixedGroupLabelTags.length"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end items-center" style="padding-top: 40px">
      <el-button plain @click="observeVisible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">確認</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get, find, chunk } from 'lodash'
import { useAsyncState, useVModel } from '@vueuse/core'
import { uiGroupTagTypeName } from '@/config/member'
import { useTagForm } from '@/views/Marketing/components/useTagForm'
import { useTagStore } from '@/views/Marketing/components/useTagStore'
import { defineComponent, getCurrentInstance, ref } from 'vue'

const uiGroupTagTypeName_ = {
  _custom: '自定義',
  ...uiGroupTagTypeName,
  _other: '其他',
}

const mapSystemOrder = Object.keys(uiGroupTagTypeName_).reduce((res, key, index) => {
  res[key] = index
  return res
}, {})

export default defineComponent({
  name: 'MemberTagAdvanceSelect',
  props: {
    modelValue: { type: Array, default: () => [] },
    options: { type: Array, default: () => [] },
    show: { type: Boolean, default: false },
    max: {
      type: Number,
      default: 0,
    },
    enableCreate: { type: Boolean, default: false },
  },
  emits: ['update:show', 'update:modelValue'],
  setup (props, { emit }) {
    const observeVisible = useVModel(props, 'show', emit)

    const {
      form,
      formData,
      formRules,
      resetForm,
      submitForm,

    } = useTagForm({})

    const toggleCreate = ref(false)

    const onCreateTag = async () => {
      const valid = await submitForm()
      if (!valid) return
      const [, err] = await req.execute(0, { ...formData })
      if (err) return $message.error(err)

      resetForm()
      toggleCreate.value = false
    }

    const { $message } = getCurrentInstance().proxy
    const {
      createTag,
    } = useTagStore()

    const req = useAsyncState(createTag, {}, { immediate: false })

    return {
      observeVisible,
      form,
      formData,
      formRules,
      resetForm,
      onCreateTag,
      isLoading: req.isLoading,
      toggleCreate,
    }
  },
  data: () => ({
    nameSearch: '',

    selected: [],

    tableOptions: {
      page: 1,
      pageLimit: 16,
    },
  }),
  computed: {
    sortTags () {
      const res = [...this.options]
      res.sort((a, b) => {
        const indexA = mapSystemOrder[a.uiGroup]
        const indexB = mapSystemOrder[b.uiGroup]
        return indexA > indexB
          ? 1 : indexA < indexB
            ? -1 : 0
      })
      return res
    },
    filterTags () {
      if (!this.nameSearch) return this.sortTags
      return this.sortTags.filter(tag => tag.name.includes(this.nameSearch))
    },
    mixedGroupLabelTags () {
      const temp = [...this.filterTags]
      let groupRecord = {}
      let pageCount = 0
      return temp.reduce((data, tag) => {
        if (tag.uiGroup in groupRecord) {
          data.push({ type: 'option', option: tag })
          pageCount += 1
        } else {
          groupRecord[tag.uiGroup] = '1'
          data.push({ type: 'label', label: uiGroupTagTypeName_[tag.uiGroup] })
          pageCount += 1

          if (pageCount === this.tableOptions.pageLimit) {
            groupRecord = { [tag.uiGroup]: '1' }
            data.push({ type: 'label', label: uiGroupTagTypeName_[tag.uiGroup] })
            pageCount = 1
          }
          data.push({ type: 'option', option: tag })
          pageCount += 1
        }

        if (pageCount === this.tableOptions.pageLimit) {
          pageCount = 0
          groupRecord = {}
        }

        return data
      }, [])
    },
    showTagList () {
      return chunk(this.mixedGroupLabelTags, this.tableOptions.pageLimit)
    },
  },
  methods: {
    onClosed () {
      this.selected = []
      this.toggleCreate = false
      this.resetForm()
    },
    onOpen () {
      this.selected = this.modelValue
    },
    onSubmit () {
      this.$emit('update:modelValue', this.selected)
      this.observeVisible = false
    },
    removeSelect (id) {
      this.selected = this.selected.filter(tag => tag !== id)
    },
    disabledTag (tag) {
      if (this.max) {
        if (this.selected.length >= this.max) {
          if (this.selected.includes(tag.id)) return false
          return true
        }
      }
      return false
    },
    findSelectTag (id) {
      const exist = find(this.options, { id })
      const name = get(exist, 'name')
      return name
    },
  },
})
</script>

<style lang="scss" scoped>
.wrapper {
  @apply border border-gray-40 rounded-[4px];
}

.selected-wrapper {
  @apply flex flex-wrap max-h-[117px] overflow-y-auto p-[8px] border-b border-gray-40 gap-[8px] min-h-[45px];
}
.selected-tag {
  @apply max-w-[160px] truncate inline-block align-bottom;
}

.list-wrapper {
  @apply p-[8px] h-[372px];
}
.item-group {
  @apply grid grid-flow-col gap-3;
  grid-template-rows: repeat(8, minmax(0, 1fr));
  > * {
    line-height: 14px;
  }
}
:deep(.el-checkbox__input){
  @apply align-text-top;
}

.btn {
  @apply underline text-normal font-normal flex-shrink-0 cursor-pointer;
}
.confirm {
  @apply text-action;
}

.cancel {
  @apply text-danger;
}

.create-tag-toggle {
  @apply mb-[22px] py-[6px];
}

.btn-toggle-create {
  @apply text-[#767676] border-[#636363];
  @apply text-[12px] font-medium leading-[18px];
  @apply px-2 py-1;
  &:hover {
    @apply text-[#555] border-[#555] bg-white;
  }
}

.pagination {
  @apply py-[30px];
}

::v-deep .create-tag.el-input{
  @apply w-full;
}
</style>
