import { computed, reactive, ref, unref } from 'vue'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation'
import { mapKeys } from 'lodash'

export const useTagForm = (sourceData) => {
  const form = ref(null)

  const formData = reactive({
    name: '',
  })

  const defaultState = { ...formData }

  const formRules = {
    name: [
      noEmptyRules('請輸入標籤名稱'),
      {
        trigger: ['change', 'blur'],
        validator: (rule, value, callback) => {
          if (/\s/g.test(value)) { callback(new Error('標籤名稱不可包含空白')) }
          callback()
        },
      },
    ],
  }

  const resetForm = () => {
    mapKeys(formData, (v, k) => {
      formData[k] = defaultState[k]
    })
    if (form.value) {
      formUtils.resetForm(form.value)
    }
  }

  const syncForm = () => {
    mapKeys(formData, (_, k) => {
      const v = unref(sourceData)[k]
      formData[k] = v !== undefined ? v : defaultState[k]
    })
  }

  const submitForm = async () => {
    const valid = await formUtils.checkForm(form.value)
    if (!valid) return false
    return true
  }

  const isDataChanged = computed(() => {
    let isChanged = false
    mapKeys(formData, (v, k) => {
      if (unref(sourceData)[k] === v) return
      isChanged = true
    })
    return isChanged
  })

  return {
    form,
    formData,
    resetForm,
    syncForm,
    submitForm,
    formRules,
    isDataChanged,
  }
}
