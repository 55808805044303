import { computed, getCurrentInstance, onMounted } from 'vue'

export const useTagStore = () => {
  const { $store } = getCurrentInstance().proxy

  const shopId = $store.getters.shop

  const tags = computed(() => $store.getters['memberTag/tags'] || [])

  const getAllTags = () => {
    return $store.dispatch('memberTag/getAllTags', { shopId })
  }
  const createTag = (payload) => {
    return $store.dispatch('memberTag/createTag', { shopId, ...payload })
  }

  const updateTag = (payload) => {
    return $store.dispatch('memberTag/updateTag', { shopId, ...payload })
  }

  const fetchTagsOnMounted = () => {
    onMounted(() => getAllTags())
  }

  return {
    tags,
    getAllTags,
    createTag,
    updateTag,
    fetchTagsOnMounted,
  }
}
